@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "MerriweatherBold";
  src: url("./assets/fonts/Merriweather/Merriweather-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

body {
  color: #072969;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.logo {
  font-family: MerriweatherBold;
}

.main-font {
  font-family: MerriweatherBold;
}

.about-bg {
  background-image: url("./assets/images/library.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.activities-bg {
  background-image: url("./assets/images/swing.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contact-bg {
  background-image: url("./assets/images/pencils.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.gallery-bg {
  background-image: url("./assets/images/gallery.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.footer-bg {
  background-image: url("./assets/images/bkc_banner_03.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.footer-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(7, 41, 105, 0.9);
  z-index: 1;
}

.footer-bg > * {
  position: relative;
  z-index: 2;
}
